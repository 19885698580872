<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h4 class="text-white">
            Budget Impact Model
          </h4>
          <h2 class="text-white">
            Link Generator
          </h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h2 class="text-white">CREATE A TIMED LINK</h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-2 medium-offset-1 mb pt-5">
          <label>Start Date</label>
        </div>
        <div class="cell small-12 medium-2 mb pt-5 pl-0 pr-0">
          <datepicker format="dd/MM/yyyy" v-model="startDate"></datepicker>
        </div>
        <div class="cell small-12 medium-2 medium-offset-2 mb pt-5">
          <label>End Date</label>
        </div>
        <div class="cell small-12 medium-2 mb pt-5  pl-0 pr-0">
          <datepicker format="dd/MM/yyyy" v-model="endDate"></datepicker>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-2 medium-offset-1 mb pt-5">
          <label>Start Time</label>
        </div>
        <div class="cell small-12 medium-2 mb pt-5  pl-0 pr-0">
          <vue-timepicker
            format="hh:mm A"
            :minute-interval="5"
            v-model="startTime"></vue-timepicker>
        </div>
        <div class="cell small-12 medium-2 medium-offset-2 mb pt-5">
          <label>End Time</label>
        </div>
        <div class="cell small-12 medium-2 mb pt-5  pl-0 pr-0">
          <vue-timepicker
            format="hh:mm A"
            :minute-interval="5"
            v-model="endTime"></vue-timepicker>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div v-if="link.length === 0" class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <button @click="generateLink" class="button expanded">GENERATE</button>
        </div>
        <div v-if="link.length > 0" class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <button class="button disabled expanded">GENERATE</button>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div v-if="link.length === 0" class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <button class="button disabled expanded">SEND EMAIL</button>
        </div>
        <div v-if="link.length > 0" class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <button @click="emailForm = true" class="button expanded">SEND EMAIL</button>
        </div>
      </div>
      <div class="email-form" v-if="emailForm === true">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <label class="text-grey">Email Address</label>
            <input type="text" v-model="emailAddress">
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-6">
            <button @click="sendLink" class="button expanded">Send</button>
          </div>
          <div class="cell small-6">
            <button @click="emailForm = false" class="button expanded">Cancel</button>
          </div>
        </div>
      </div>
      <!--
        <div
          v-if="link.length > 0"
          class="grid-x grid-padding-x">
          <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
            <h4 class="text-white">Link</h4>
          </div>
        </div>
        <div
          v-if="link.length > 0"
          class="grid-x grid-padding-x">
          <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
            <div class="link-wrap">
              <input
                class="link-holder"
                v-on:focus="$event.target.select()"
                ref="clone"
                readonly
                :value="link"/>
              <button @click="copy" class="link-copy">COPY LINK</button>
            </div>
          </div>
        </div>
      -->
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-40">
          <p class="text-white text-small">
            Biogen-192046 Date of preparation: January 2023
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../../assets/logos/biogen_logo.svg" alt="" />
        <img class="bottom-logo" src="../../assets/logos/Byooviz_logo.png" alt="" />
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'Links',
  components: {
    Datepicker,
    VueTimepicker,
    appMessage: Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      link: '',
      linkId: 0,
      message: '',
      showMessage: false,
      viewportHeight: 0,
      startDate: new Date(),
      startTime: '09:00 AM',
      endDate: new Date(),
      endTime: '10:00 AM',
      emailForm: false,
      emailAddress: '',
    };
  },
  watch: {
    startDate(value) {
      this.endDate = value;
    },
  },
  methods: {
    sendLink() {
      const postData = {};
      postData.emailAddress = this.emailAddress;
      postData.link = this.link;
      postData.linkId = this.linkId;
      axios.post(`/links/sendLink.json?token=${this.token}&user=${this.userid}`, postData)
        .then(() => {
          this.emailForm = false;
          this.message = 'Your link has been sent.';
          this.showMessage = true;
        })
        .catch(() => {
          this.message = 'Sorry, something went wrong.';
          this.showMessage = true;
        });
    },
    /*
    getLinks() {
      axios.get(`/links/getLinks.json?token=${this.token}&user=${this.userid}`)
        .then((response) => {
          this.links = response.data.links;
        })
        .catch(() => {
          this.message = 'Sorry, something went wrong.';
          this.showMessage = true;
        });
    },
    */
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
    generateLink() {
      const postData = {};
      postData.startDate = this.startDate;
      postData.startTime = this.startTime;
      postData.endDate = this.endDate;
      postData.endTime = this.endTime;
      postData.userid = this.userid;
      axios.post(`/links/createTimedLink.json?token=${this.token}`, postData)
        .then((response) => {
          this.message = response.data.message;
          this.link = response.data.link;
          this.linkId = response.data.id;
          this.showMessage = true;
        })
        .catch(() => {
          this.message = 'Sorry, the link could not be generated. Please try again.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.viewportHeight = window.innerHeight;
    // this.getLinks();
  },
};
</script>

<style lang="scss">
  .vdp-datepicker input {
    font-size: 1rem;
    font-family: "Helvetica Neue-Condensed", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 18px;
    text-transform: lowercase;
    color: #8c8d8c;
  }
  .vue__time-picker { width: 100% }
  .vue__time-picker input.display-time {
    width: 100%;
    font-size: 1rem;
    font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 18px;
    text-transform: lowercase;
    color: #8c8d8c;
  }
</style>
